.v-auction-allocation-tab input{
    max-width: 100%;
}

.v-auction-allocation-export-all{
    text-align: right;
}

.v-auction-allocation-tab{
    padding-top: 10px;
}