.bold-text{
    font-weight: bold;
    font-size: 14px;
}

.bold-subtotal-text{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px
}

.bold-total-text{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
}

.bold-payment-text{
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.bold-subtotal-negative-summary{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px;
    color: red;
}

.bold-subtotal-positive-summary{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px;
    color: black;
}

.payment{
    background-color: #f5f5f5;
    font-style: italic;
    font-size: 15px;
    color: rgb(78, 77, 77);
}

.payment-text{
    background-color: #f5f5f5;
    font-size: 13px;
    color: black;
}

.negative-summary{
    font-style: italic;
    font-size: 15px;
    color: #ff0000;
}

.positive-summary{
    font-style: italic;
    font-size: 15px;
    color: black;
}

.bold-negative-summary{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
    color: red;
}

.bold-positive-summary{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
    color: black;
}