.vtabs-container {
  font-family: Verdana, Tahoma, Geneva, sans-serif;
  font-size: 14px;
}

.vtabs-container .nav-item  {
  color: black;
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(230, 227, 227);
}

.vtabs-container .nav-item:hover  {
  background-color: rgb(245, 244, 244);
}

.vtabs-container .nav-item.disabled  {
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(245, 243, 243);
}

.vtabs-container .nav-item.active  {
  color: black;
  background-color: white;
  border-bottom: 0;
}

.vtabs-container .nav-item.active:hover  {
  background-color: white;
}

.vtabs-container .tab-pane{
  border: 1px solid #dee2e6;
  border-top-color: white;
  min-height: 67vh;  
  padding-left: 4px;
  padding-right: 4px;
}

.vtabs-container .v-infinite-width{
  overflow-x: auto;
  white-space: nowrap;
}

.vtabs-container .v-infinite-width > div {
  display: inline-block;
}

.v-tab-button {
  background-color: #0675a6;
  color: white;
  margin: 3px 3px 0 0;
}

.v-tab-button i {
  margin-right: 3px;
}

@media screen and (min-width: 768px) and (max-width: 1024px)  {
  .vtabs-container .tab-pane{
    min-height: 53vh;  
  }
}