.v-pnl-summary-table td,
.v-pnl-summary-table th,
.v-pnl-detail-table td,
.v-pnl-detail-table th {
    padding: 0 5px !important;
    text-align: center;
    vertical-align: middle !important;
}

.v-pnl-summary-table th{
    color: black;
}

.v-pnl-detail-table th {
    color: white;
}

.v-pnl-summary-table .v-pnl-date-row,
.v-pnl-detail-table .v-pnl-date-row {
    font-weight: 600;
    color: #141414;
    background-color: #E7ECEF;
}

.v-pnl-summary-table .v-pnl-date-header ,
.v-pnl-detail-table .v-pnl-date-header {
    color:#141414;
    background-color: #E7ECEF;
}

.v-pnl-summary-table .v-row-group-1,
.v-pnl-detail-table .v-row-group-1 {
    background-color: rgba(255, 253, 253, 0.76);
}

.v-pnl-summary-table .v-row-group-2,
.v-pnl-detail-table .v-row-group-2{
    background-color: rgba(241, 241, 241, 0.76);
}

.v-pnl-summary-table .v-sum-of-borders{
    background-color: rgba(234, 241, 247, 0.76);
}

.v-fixed-button-down {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.v-fixed-button-up {
    position: fixed;
    bottom: 40px;
    right: 10px;
}

.v-fixed-button-clear {
    position: fixed;
    bottom: 70px;
    right: 10px;
}

.v-fixed-button-up button,
.v-fixed-button-down button,
.v-fixed-button-clear button {
    background-color: rgba(234, 241, 247, 0.76) !important;
    color: black !important;
    opacity: 0.5;
    width: 75px;
    text-align: left;
    padding: 0;
}

.v-pnl-summary-table .v-data-group-1,
.v-pnl-detail-table .v-data-group-1{
    background-color: #C5DDF0;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-pnl-summary-table .v-data-group-2,
.v-pnl-detail-table .v-data-group-2{
    background-color: #F8F9FA;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-pnl-selected-row {
    border: 2px solid black !important;
    background-color: rgb(247, 244, 226) !important;
}