.t-file-upload-icon {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.t-icon-button{
    cursor: pointer;
}

.t-disabled-link-button, .t-link-button{
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

span.t-disabled-link-button, .btn.t-disabled-link-button {
    opacity: 0.65;
    cursor: default !important;
    pointer-events: none;
}

.t-upload-file-label {
    padding-left: 5px;
    cursor: pointer;
    font-weight: 600;
}

.t-upload-file-label:hover {
    text-decoration: underline;
}