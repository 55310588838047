.t-buy-button {
    width: 120px;
	background: linear-gradient(221deg, transparent 25%, #97c713 30%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
}

.unselect-buy {
    background: linear-gradient(221deg, transparent 26%, #555 84%) ;
}

.t-sell-button {
    width: 120px;
    background: linear-gradient(45deg, transparent 25%, #e0142e 30%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    margin-left: -50px;
    border-radius: 5px;
}

.unselect-sell {
    background: linear-gradient(45deg, transparent 25%, #555 70%);
}

.t-position-div {
    margin: 12px 0px 0px 25px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.t-position-label {
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-table-buy {
    align-items: center;
    background-color: #97c713;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    font-size: 0.6875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.5px;
    min-width: 20px;
    padding: 4px 8px;
    text-transform: uppercase;
}

.t-table-sell {
    align-items: center;
    background-color: #e0142e;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    font-size: 0.6875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.5px;
    min-width: 20px;
    padding: 4px 8px;
    text-transform: uppercase;
}


.cost-input {
    width: 70%;
    height: 30px;
    padding-left: 2px;
}

.cost-save-button {
    padding: 2px;
    height: 30px;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #C75313;
    color: #fff;
    border-radius: 1px;
    border: limegreen;
    font-weight: 600;
    font-size: 14px;
}