.react-datepicker__close-icon::after { 
    background-color: #99A3A4  !important;
}

.t-datepicker-content{
    margin: 12px 0px 0px 25px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.t-datepicker-label {
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-datepicker-content input {
    padding-left: 5px;
    font-size: 14px;
    height: 38px;
    width: 250px;
    border-radius: 1px ;
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.t-datepicker-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.t-datepicker-content input:hover {
    border-color: hsl(0,0%,70%);
}

.t-datetimepicker input {
    width: 192px;
}

.t-datepicker input {
    width: 150px;
}