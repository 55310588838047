.v-auction-confirmation div{
    padding: 0;
    padding-right: 15px;
}

.v-auction-warning{
    color: red;
    font-weight: 600;
}

.v-auction-bid .v-tag-input {
    min-height: 50px;
}