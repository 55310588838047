.MuiBadge-anchorOriginTopRightRectangle {
    top: 3px !important;
    right: -10px !important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.MuiBadge-colorError {
    color: #fff;
    background-color: #c14940 !important;
}

.t-user-info-content {
    color: #454D4E;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.t-user-info-content span {
    margin-left: 5px;
    margin-right: 10px;
}

.t-user-log-out button {
    border-width: 0px;
    background: none;
    padding: 2px;
}

.t-user-log-out-icon {
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.t-user-log-out-icon:hover {
    color: #ff9900;
}

.t-user-log-out button:focus {
    border-width: 0px;
    background: none;
    outline: 0px;
}

.t-external-link {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .t-user-info-content {
        display: block;
    }

    .t-user-detail {
        color: #95a5a6;
    }
    
}

