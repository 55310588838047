html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

.t-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
  
.t-button {
    display: inline-block;
    *display: inline;
    zoom: 1;
    padding: 6px 20px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #bbb;
    overflow: visible;
    font: bold 13px arial, helvetica, sans-serif;
    text-decoration: none;
    white-space: nowrap;
    color: #555;
    
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,1)), to(rgba(255,255,255,0)));
    background-image: -webkit-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -moz-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -ms-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -o-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
    
    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -ms-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    background-clip: padding-box; /* Fix bleeding */
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-shadow: 0 1px 0 rgba(255,255,255, .9);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.t-button:hover {
    background-color: #eee;
    color: #555;
}
  
.t-button:active {
    background: #e9e9e9;
    position: relative;
    top: 1px;
    text-shadow: none;
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
}
  
.t-button[disabled], .t-button[disabled]:hover, .t-button[disabled]:active{
    border-color: #eaeaea;
    background: #fafafa;
    cursor: default;
    position: static;
    color: #999;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
}

.t-button:focus {
    outline: none;
}
  
  /* Smaller buttons styles */
  
.t-button.small{
    padding: 4px 12px;
}
  
  /* Larger buttons styles */
  
.t-button.large{
    padding: 12px 30px;
    text-transform: uppercase;
}
  
.t-button.large:active{
    top: 2px;
}

.t-input {
    height: 38px;
    width: 250px;
    border-radius: 1px;
    border: 1px solid hsl(0,0%,80%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
    padding-left: 6px;
}

.t-input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.t-font {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.t-font-size {
    font-size: 14px;
}

.t-bold-text {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.t-orange-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #C75313;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
}

.t-orange-button:hover {
    background-color: #E76D2E;
    color: #fff;
}

.t-orange-button:focus {
    box-shadow: none;
}

.t-gray-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #85929E;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
}

.t-gray-button:hover {
    background-color: #AEB6BF;
    color: #fff;
}

.t-gray-button:focus {
    box-shadow: none;
}

.t-red {
    color: #C70039 ;
}

.text-field {
    width: 70%;
    margin: 10px 0px 0px 25px !important;
}