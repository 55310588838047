.v-login-border {
    margin: auto;
    width:60%;
    min-height: calc(100vh - 90px);
}

.v-login-content {
    margin: auto;
    padding: 30% 23% 15% 23%;
}

.v-login-form {
    margin-top: 4%;
}

.v-login-logo {
    text-align: center;
}

.v-login-content input {
    padding-left: 12px;
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-login-content input:hover {
    border-color: hsl(0,0%,70%);
}

.v-login-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-login-placeholder {
    border-radius: 0;
}

.v-login-btn {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 2%;
    border: 1px solid  hsl(0,0%,80%);
    border-width: 1px ;
    margin-bottom: 4%;
}

.v-login-btn:hover {
    background-color: #232f3e;
}


@media screen and (max-width: 768px) {
    .v-login-content {
        padding: 40% 0px 0px 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px)  {
    .v-login-content {
        padding: 40% 5% 0px 5%;
    }
}