.table-summary {
    background-color: #c1bdbd;
    text-align: right;
    font-size: 14px;
}

.summary {
    background-color: #c1bdbd;
    font-size: 14px;
}

.overall-table-summary{
    background-color: #a09d9d;
    text-align: right;
    font-size: 14px;
}

.overall-summary{
    background-color: #a09d9d;
    font-size: 14px;
}

.bold-summary{
    font-weight: bold;
}
