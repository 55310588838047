.react-datepicker__close-icon::after { 
    background-color: #99A3A4  !important;
}

.v-datepicker-content{
    display: inline-block;
}

.v-datepicker-label {
    padding-bottom: 5px;
    font-weight: 400;
}

.v-datepicker-content input {
    padding-left: 5px;
    font-size: 14px;
    height: 38px;
    width: 250px;
    border-radius: 4px ;
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-datepicker-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-datepicker-content input:hover {
    border-color: hsl(0,0%,70%);
}

.v-datetimepicker input {
    width: 192px;
}

.v-datepicker input {
    width: 150px;
}