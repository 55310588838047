.v-custompnl-detail {
    margin: 30px 0px 0px 0px;
}

.v-custompnl-messagebox {
    margin-top: 15px;
}

.v-custompnl-add-direction {
    text-align: right;
    margin-top: 5px;
    padding: 0px
}

.v-custompnl-no-direction {
    font-weight: 600;
    margin: 5px; 
    padding: 5px;
}