.table-hover > tbody > tr:hover {
    background-color: #D2D2D2;
  }

.table {
  margin: 0.5vh 0vh 0.5vh 0vh;
  vertical-align: top;
  font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.table th,
.table td,
.table input {
  padding: 0.01rem;
  font-size: smaller;
}

.table tr,
.table th,
.table td{
  height: 23px;
}

/*Not to show up down icons on number input*/
/* Chrome, Safari, Edge, Opera */
.table input::-webkit-outer-spin-button,
.table input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.table input[type=number] {
  -moz-appearance: textfield;
}

.table .v-negative{
  color: red;
}

.table .v-positive{
  color: green;
}

.table .v-number{
  text-align: right;
}

.table .v-text{
  text-align: left;
}

.table input{
  max-width: 3rem;
}

.table input.v-input-4{
  max-width: 3.8rem;
}

.v-header-wrapper .v-header-content{
  padding-right: 0.2rem;
}

.table th.v-main-header{
  min-width: 9rem;
  padding-bottom: 4px;
}

.table.v-table-fit {
  display: inline-block;
  width: auto;
  table-layout: auto;
}

.table.v-table-fit thead th, table.v-table-fit tfoot th {
  width: auto !important;
}

.table.v-table-fit tbody td, table.v-table-fit tfoot td {
  width: auto !important;
}

.v-header-wrapper .v-header-content,
.table td {
  min-width: 72px;
  padding-left: 5px;
  padding-right: 5px;
}

.v-header-wrapper .v-header-content,
.table th,
.table td {
  font-size: 14px;
  white-space: nowrap;
}

.table th,
.table td {
  border: 1px solid #dee2e6 !important;
  border-collapse: collapse;
}

.table .v-main-header{
  border-top: 1px solid white !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}

.table .v-text-headers *,
.table .v-footer-row{
  font-weight: 600;
  text-align: center;
  vertical-align: top;
}

.table .v-footer-row .v-text{
  text-align: center;
}

.v-margin-table-buttons-outer{
  position: absolute;
  right: 0;
  width: 100%;
}

.v-margin-table-buttons-inner {
  right: 0;
  margin-top: 5px;
  margin-right: 25px;
  position: absolute;
  font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-margin-table-buttons-inner .v-icon-button {
  margin-left: 5px;
}

.table .v-footer-row{
  background-color: #d8d7d7 !important;
}

.v-header-wrapper,
.v-header-content{
  width: 100% !important;
  height: 100% !important;
}

/* Custom column classes */
.table .v-column-narrow-bold {
  min-width: 48px !important;
  font-weight: 600;
  text-align: center;
}

.table .v-selected-cell{
  background-color: rgba(255, 255, 4, 0.575);
}

.table .selected-row { 
  border: 2px solid #000!important;
  background-color: #f7f4e2!important;
}