.t-left-side-container .MuiAccordionDetails-root {
    padding: 12px 12px 0 3px;
}

.t-left-side-container-content .rdg {
    height: 587px !important;
}

.t-left-side-container-content .MuiPaper-elevation1 {
    box-shadow: none;
}