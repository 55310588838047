.v-paging{
    white-space: nowrap;
    margin: 5px 0;
}

.v-paging button,
.v-paging label {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.v-paging ul{
    margin: 0;
    padding-left: 15px;
}

.v-paging .page-link {
    padding: 5px !important;
}

.v-paging .v-paging-record-count  {
    display: inline-block;
    margin: 4.5px 0;
    margin-right: 5px;
}

.v-paging .v-paging-record-count select {
    margin: 0 5px;
}

.v-paging li.v-selected button {
    font-weight: 900;
}
