.v-pnl-detail-table .v-weak-column{
    opacity: 0.5;
}

.v-pnl-detail-table span.v-negative{
    color: red;
  }
  
.v-pnl-detail-table span.v-positive{
    color: green;
}

.v-pnl-detail-table .v-pnl-column{
    background-color: #FEF9E7 !important;
    color: #141414 !important;
}

.v-pnl-detail-table .pnl-unit-label {
    font-size: 10px;
    font-weight: 400;
}

.v-pnl-detail-table .pnl-darker-column {
    background-color: #eeeeee;
}

.v-pnl-detail-table .pnl-tso-label {
    font-weight: 400;
}
