.v-spinner-modal-dialog {
    margin-left: auto;
    margin-right: auto;
    top:20%;
    width:50px;
}

.v-spinner-modal .modal-content  {
    border: none;
}

.v-spinner-modal .v-spinner-icon {
    text-align: center;
    display: inline-block;
    width: auto;
    position: absolute;
    color: #2a7cbb;
}