.spaces-centered-vertically {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.spaces-centered {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
}

.spaces-clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.spaces-fixedsize-layout {
	overflow: hidden;
	position: relative;
}

.spaces-fullpage-layout {
	position: fixed;
	overflow: hidden;
}

.spaces-resize-handle {
	transform: translateZ(0px);
    background-color:#d7e4ef !important;
    width: 10px !important;
    display: block;
    height: 100%;
    cursor: ew-resize;
	z-index: 50 !important;
	margin-left: 12px;
	border-radius: 3px;
}

.spaces-resize-handle:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
}

.spaces-resize-handle:after {
	margin: 450px;
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(50% - 20px);
    display: flex;
    background: #232f3e;
    border-radius: 34px;
    width: 4px;
    height: 45px;
    margin-left: 3px;
    opacity: 1;
}

.spaces-touch-handle {
	position: absolute;
	z-index: 9998;
	pointer-events: all;
	background: transparent;
}

.spaces-resize-handle.resize-left {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-left:before {
	cursor: ew-resize;
}

.spaces-touch-handle.resize-left {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-right {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-right:before {
	cursor: ew-resize;
}

.spaces-touch-handle.resize-right {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-top {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-top:before {
	cursor: n-resize;
}

.spaces-touch-handle.resize-top {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-bottom {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-bottom:before {
	cursor: s-resize;
}

.spaces-touch-handle.resize-bottom {
	left: 0;
	right: 0;
}

.spaces-space {
	overflow: hidden;
	touch-action: none;
	box-sizing: border-box;
}

.spaces-resizing .spaces-space {
	transition: none !important;
}

.spaces-space .spaces-space-inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
}

.spaces-space.scrollable .spaces-space-inner,
.spaces-space.scrollable-a {
	overflow: auto;
	touch-action: auto;
}