.v-base-page {
    min-height: calc(110vh - 88px);
    overflow: hidden;
    padding-top: 5px;
}

.v-base-page, .v-base-page .container  {
    max-width: calc(110vw - 175px) !important;
    margin-left: auto;
    margin-right: auto;
}

.v-content-container {
    background-color: #fff;
    border-top: 1px solid #eaeded;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-bottom: 16px;
}

.v-content-container .card-header {
    font-size: 1.2rem;
    line-height: 2rem;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    font-weight: 700;
    color: #16191f;
}

.v-content-container .card {
    border: none;
}

@media screen and (max-width: 768px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 25px) !important;
    }
}


@media screen and (max-width: 1024px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 45px) !important;
    }
}