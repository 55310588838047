.v-flows-hour-row {
    font-weight: 600;
    color: #141414;
    background-color: #E7ECEF;
}

.v-infinite-width{
    display: flex;
    margin-right: -5px;
}

.v-row-group-1 {
    background-color: #a1b6c7;
}

.v-row-group-2{
    background-color: #F8F9FA;
}

.v-data-group-1{
    background-color: #C5DDF0;
    color: #141414;
    border-right: 3px rgb(151, 151, 151) solid !important;
    border-bottom: 3px rgb(151, 151, 151) solid !important;
    text-align: center;
}

.v-data-group-2{
    background-color: #F8F9FA;
    color: #141414;
    border-right: 3px rgb(151, 151, 151) solid !important;
    border-bottom: 3px rgb(151, 151, 151) solid !important;
    text-align: center;
}

.v-flows-date-header {
    color:#141414;
    background-color: #E7ECEF;
    border: 'solid 1px #dadada';
}


