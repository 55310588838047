.v-vertical-tab-container .nav-pills {
  height: 100%;
  white-space: nowrap;
  font-weight: 600;
}

.v-vertical-tab-container .nav-item {
  margin-top: 1px;
  color: black;
  font-weight: 600;
  background-color: rgb(230, 227, 227);
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
}

.v-vertical-tab-container .v-vertical-tab-header {
  height: max-content;
  flex-grow: unset;
} 

.v-vertical-tab-container .v-vertical-tab-header .nav-link {
  color: #000;
}

.v-vertical-tab-container .v-disabled-vertical-tabs .nav-link  {
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(245, 243, 243);
  pointer-events: none;
  color: #6c757d;
}

.v-vertical-tab-container .v-vertical-tab-header .nav-link.active {
  color: black;
  background-color: white;
}

.v-vertical-tab-container .tab-pane {
  padding: 10px;
  min-height: max-content;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.v-vertical-tab-container .row {
  margin-right: 0;
  flex-wrap: nowrap;
}

.v-vertical-tab-content {
  margin-bottom: 10px;
  width: 100%;
}

.v-vertical-tab-container,
.v-vertical-tab-content {
  width: 100%;
}

.v-infinite-width .tab-pane {
  white-space: nowrap;
  width:fit-content;
}

.v-infinite-width .tab-pane .v-table-list div {
  display: inline-block;
} 