.v-category-content {
    text-align: center;
    padding: 1% 6% 1% 6%; 
}

.v-category-content > div {
    margin: 10px;
    vertical-align: text-top;
}

.v-category-title {
    font-size: medium;
    width: auto;
    margin-bottom: 0;
}