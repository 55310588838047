.v-cb-invoice-summary-table td,
.v-cb-invoice-summary-table th {
    padding: 0 5px !important;
    text-align: center;
    vertical-align: middle !important;
}

.v-cb-invoice-summary-table th {
    color: black;
}

.v-cb-invoice-summary-table .v-cb-invoice-date-header {
    color:#141414;
    background-color: #E7ECEF;
}

.v-cb-invoice-summary-table .v-row-group-1 {
    background-color: rgba(255, 253, 253, 0.76);
}

.v-cb-invoice-summary-table .v-row-group-2 {
    background-color: rgba(241, 241, 241, 0.76);
}

.v-cb-invoice-summary-table .v-data-group-1 {
    background-color: #C5DDF0;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-cb-invoice-summary-table .v-data-group-2 {
    background-color: #F8F9FA;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-cb-invoice-selected-row {
    border: 2px solid black !important;
    background-color: rgb(247, 244, 226) !important;
}

.v-cb-invoice-summary-table .v-weak-column{
    opacity: 0.5;
}

.v-colored-header-v1 {
    background-color: #acd3e6 !important;
}

.v-colored-header-v2 {
    background-color :#d9d9d9;
}
