.v-popever {
    background-color:rgb(241, 208, 99);
    border: none;
    border-radius: 4px;
}

.v-popever > * {
    color:rgb(80, 78, 78);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-popever .arrow {
    visibility: hidden;
}