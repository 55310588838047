.v-checkbox-group {
    white-space: nowrap;
    margin-top: 9px;
}

.v-checkbox-group .v-checkbox-title label {
    margin: 0;
}

.v-checkbox-group .v-checkbox-title {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-weight: 600;
    font-size: 14px;
    width: fit-content;
    display: inline-block;
    margin: 0 3px 0 0;
}

.v-checkbox-group .v-checkbox-group-inputs {
    display: flex;
    flex-wrap: wrap;
}

.v-checkbox-group .v-checkbox-group-inputs input {
    margin: 0 5px 0 0;
}

.v-checkbox-group-inputs > label {
    margin-left: 15px;
}