.v-horizontal-table{
    display:initial;
}

.v-horizontal-table .v-filled-header{
    background-color: rgba(231, 209, 167, 0.719);
}

.v-horizontal-table .v-offer-type-sell{
    background-color: rgba(167, 218, 231, 0.719);
}

.v-horizontal-table .v-offer-type-buy{
    background-color: rgba(233, 170, 155, 0.719);
}

.v-horizontal-table td.v-filled-header-first,
.v-horizontal-table td.v-filled-header,
.v-horizontal-table th.v-filled-header{
    border-right: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header{
    border-top: 2px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header:nth-child(2n-1){
    border-right: 1px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header:nth-child(2n){
    border-left: 1px solid black !important;
}

.v-horizontal-table th.v-info-end,
.v-horizontal-table td.v-info-end {
    border-right: 2px solid black !important;
}

.v-horizontal-table td.v-info-order {
    border-right: 2px solid black !important;
    border-left: 1px solid black !important;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;
    text-align: center;
    font-weight: 600;
}

.v-horizontal-table .v-number{
    text-align: right;
    padding-left: 10px;
    padding-right: 2px;
    font-weight: initial;
    border: 1px solid black !important;
}

.v-horizontal-table .v-text{
    padding-right: 10px;
    padding-left: 2px;
    font-weight: initial;
    border: 1px solid black !important;
}

.v-horizontal-table .v-last-row{
    border-bottom: 2px solid black !important;
}