html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    background: linear-gradient(to right,#eee,#eaeded) !important;
}

.v-font {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-font-size {
    font-size: 14px;
}

.v-gray {
    color: #454D4E;
}

.v-red {
    color: #d93025;
}

.v-green {
    color: green;
}

.v-light-blue {
    color: #546375;
}

.v-white {
    color: #fff;
}

.v-label {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-weight: 600;
    font-size: 14px;
}

.v-button {
    background-color: #0675a6;
}

.v-cancel-button {
    background-color: #8f9396;
}

.v-button,
.v-cancel-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: white;
    font-size: 14px;
}

.v-button:hover {
    color: white;
    background-color: #2a7cbb;
}

.v-cancel-button:hover {
    color: white;
    background-color: #a1a5a8;
}

.v-button:focus,
.v-cancel-button:focus {
    box-shadow: none;
}

.v-disabled-link-button,
.v-link-button{
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

.v-link-button{
    cursor: pointer;
}

.v-link-button:hover {
    color: #0675a6;
}

.btn.v-link-button:hover {
    color: white;
}

span.v-disabled-link-button,
.btn.v-disabled-link-button {
    opacity: 0.65;
    cursor: default !important;
    pointer-events: none;
}

.v-icon-button{
    cursor: pointer;
}

.v-bullet-list{
    list-style-type:disc
}

.v-bullet-list li{
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-size: 14px;
}

.v-table-list > div {
    flex: 1 1 auto;
    margin: 8px;
}

.v-checkBox {
    width: auto;
    display: block !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.v-checkBox input {
    margin-right: 3px;
}

.v-input {
    height: 38px;
    width: 250px;
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
}

.v-input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

/* Remove Arrows/Spinners from Input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:hover {
    border-color: hsl(0,0%,70%);
}

.v-negative{
    color: red !important;
}
  
.v-positive{
    color: green !important;
}
html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

.t-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
  
.t-button {
    display: inline-block;
    *display: inline;
    zoom: 1;
    padding: 6px 20px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #bbb;
    overflow: visible;
    font: bold 13px arial, helvetica, sans-serif;
    text-decoration: none;
    white-space: nowrap;
    color: #555;
    background-image: -o-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
    transition: background-color .2s ease-out;
    background-clip: padding-box; /* Fix bleeding */
    border-radius: 3px;
    text-shadow: 0 1px 0 rgba(255,255,255, .9);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.t-button:hover {
    background-color: #eee;
    color: #555;
}
  
.t-button:active {
    background: #e9e9e9;
    position: relative;
    top: 1px;
    text-shadow: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
}
  
.t-button[disabled], .t-button[disabled]:hover, .t-button[disabled]:active{
    border-color: #eaeaea;
    background: #fafafa;
    cursor: default;
    position: static;
    color: #999;
    box-shadow: none !important;
    text-shadow: none !important;
}

.t-button:focus {
    outline: none;
}
  
  /* Smaller buttons styles */
  
.t-button.small{
    padding: 4px 12px;
}
  
  /* Larger buttons styles */
  
.t-button.large{
    padding: 12px 30px;
    text-transform: uppercase;
}
  
.t-button.large:active{
    top: 2px;
}

.t-input {
    height: 38px;
    width: 250px;
    border-radius: 1px;
    border: 1px solid hsl(0,0%,80%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
    padding-left: 6px;
}

.t-input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.t-font {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.t-font-size {
    font-size: 14px;
}

.t-bold-text {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.t-orange-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #C75313;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
}

.t-orange-button:hover {
    background-color: #E76D2E;
    color: #fff;
}

.t-orange-button:focus {
    box-shadow: none;
}

.t-gray-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #85929E;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
}

.t-gray-button:hover {
    background-color: #AEB6BF;
    color: #fff;
}

.t-gray-button:focus {
    box-shadow: none;
}

.t-red {
    color: #C70039 ;
}

.text-field {
    width: 70%;
    margin: 10px 0px 0px 25px !important;
}
.v-login-border {
    margin: auto;
    width:60%;
    min-height: calc(100vh - 90px);
}

.v-login-content {
    margin: auto;
    padding: 30% 23% 15% 23%;
}

.v-login-form {
    margin-top: 4%;
}

.v-login-logo {
    text-align: center;
}

.v-login-content input {
    padding-left: 12px;
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-login-content input:hover {
    border-color: hsl(0,0%,70%);
}

.v-login-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-login-placeholder {
    border-radius: 0;
}

.v-login-btn {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 2%;
    border: 1px solid  hsl(0,0%,80%);
    border-width: 1px ;
    margin-bottom: 4%;
}

.v-login-btn:hover {
    background-color: #232f3e;
}


@media screen and (max-width: 768px) {
    .v-login-content {
        padding: 40% 0px 0px 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px)  {
    .v-login-content {
        padding: 40% 5% 0px 5%;
    }
}
.v-main-page-border {
    padding-top: 70px;
    min-height: calc(100vh - 90px);
}

.v-main-page-content {
    padding: 0% 2% 0% 2%;
    overflow-x: hidden;
}
.v-base-page {
    min-height: calc(100vh - 88px);
    overflow: hidden;
    padding-top: 70px;
}

.v-base-page, .v-base-page .container  {
    max-width: calc(100vw - 175px) !important;
    margin-left: auto;
    margin-right: auto;
}

.v-content-container {
    background-color: #fff;
    border-top: 1px solid #eaeded;
    border-radius: 4px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-bottom: 16px;
}

.v-content-container .card-header {
    font-size: 1.2rem;
    line-height: 2rem;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    font-weight: 700;
    color: #16191f;
}

.v-content-container .card {
    border: none;
}

@media screen and (max-width: 768px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 25px) !important;
    }
}


@media screen and (max-width: 1024px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 45px) !important;
    }
}
.v-modal {
    top:20%;
}

.v-modal-30w{
    width:450px;
    max-width: none !important;
}

.v-modal-50w{
    width:500px;
    max-width: none !important;
}

.v-modal-60w{
    width:600px;
    max-width: none !important;
}

.v-modal-body .container > div {
    margin-top: 2%;
}

.modal-content {
    border-radius: 0;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.modal-header {
    background-color: #fafafa;
}

.modal-title {
    font-size: 18px !important;
}

.v-modal-error-icon-col{
    width: auto;
}

.v-modal-error-icon{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    text-align: center;
}
.v-category-item {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    border-style: solid ;
    border-width: 1px ;
    border-color: hsl(0,0%,80%);
    padding: 3% 1% 1% 1%;
    min-width: 164px;
    min-height:  158px;
    cursor: pointer;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    margin: 10px;
    vertical-align: text-top;
}

.v-category-item:hover {
    background-color: #546375;
    border-color: #657282;
}

.v-category-item-title {
    padding-top: 10px;
    max-width: 145px;
    font-weight: 700;
}

.v-category-item:hover .v-category-item-title p {
    color: #fff;
}

.v-category-item:hover .v-category-item-icon i {
    color: #fff;
}

.v-category-item a {
    text-decoration: none;
}
.v-category-content {
    text-align: center;
    padding: 1% 6% 1% 6%; 
}

.v-category-content > div {
    margin: 10px;
    vertical-align: text-top;
}

.v-category-title {
    font-size: medium;
    width: auto;
    margin-bottom: 0;
}

.v-filter-container > div,
.v-filter-container > .container .row {
    margin-top: 2px;
    margin-bottom: 10px;
}

.v-filter-group {
    display: flex;
}

.v-filter-label {
    min-width: 128px;
    padding-top: 10px;
}

.v-filter-buttons {
    text-align: right;
}

.v-filter-button {
    margin-left: 8px;
}

.v-filter-button i {
    margin-right: 3px;
}

.v-filter-taginput {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .v-filter-group {
        display: block;
    }

    .v-filter-container > div,
    .v-filter-container > .container .row  {
        margin-top: 0px;
        margin-bottom: 2%;
    }
}
.v-main-container, .v-main-container-flex {
    margin-bottom: 1%;
}

.v-main-container-flex {
    display: flex;
    overflow-y: auto;
}
.react-datepicker__close-icon::after { 
    background-color: #99A3A4  !important;
}

.v-datepicker-content{
    display: inline-block;
}

.v-datepicker-label {
    padding-bottom: 5px;
    font-weight: 400;
}

.v-datepicker-content input {
    padding-left: 5px;
    font-size: 14px;
    height: 38px;
    width: 250px;
    border-radius: 4px ;
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-datepicker-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-datepicker-content input:hover {
    border-color: hsl(0,0%,70%);
}

.v-datetimepicker input {
    width: 192px;
}

.v-datepicker input {
    width: 150px;
}
.v-tag-input {
    border: 1px solid hsl(0,0%,80%);
    border-radius: 4px;
    position:relative;
    min-height: 80px;
    padding: 1%;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-tag-input:hover {
    border: 1px solid hsl(0,0%,70%);
}

.v-tag-input div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
}

.v-tag-input input.ReactTags__tagInputField {
    border:0;
    box-shadow: none;
    -webkit-appearance: none;
    width: 100%;

}

.v-tag-input input.ReactTags__tagInputField,
.v-tag-input .ReactTags__suggestions{
    font-size: 14px;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-tag-input .ReactTags__suggestions {
    max-height: 160px; 
    overflow: auto;
    border: 1px solid hsl(0,0%,80%);
}

.v-tag-input .ReactTags__suggestions ul{
    margin: 0;
    padding: 0;
}

.v-tag-input input.ReactTags__tagInputField:focus {
    outline: none;
}

/* Styles for selected tags */
.v-tag-input div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    display: inline-block;
    padding: 5px;
    margin: 2px 2px;
    border-radius: 2px;
    font-size: 14px;
}

.v-tag-input div.ReactTags__selected a.ReactTags__remove,
.v-tag-input div.ReactTags__selected i.ReactTags__remove  {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

.v-tag-input div.ReactTags__suggestions {
    position: absolute;
}
.v-tag-input div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
.v-tag-input div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.v-tag-input div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
.v-dropdown-content {
    display: inline-block;
}

.v-dropdown-label {
    padding-bottom: 5px;
}

.v-dropdown-content .css-1pahdxg-control {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-dropdown-content .css-1pahdxg-control:hover {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-dropdown-content .css-9gakcf-option {
    background-color: #566573 !important;
}

.v-dropdown-content .css-1n7v3ny-option {
    background-color: #E5E7E9 !important;
}

.v-dropdown-content .v-select__control {
    align-items : baseline;
}

.v-dropdown-content .v-select__single-value, .css-yt9ioa-option, .css-9gakcf-option, .css-1n7v3ny-option, .css-107lb6w-singleValue {
    font-size: 14px !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif !important;
}

.v-dropdown-content  .v-select__single-value {
    color: #000 !important;
}

.v-dropdown-content .css-g1d714-ValueContainer {
    position: static !important;
    position: initial !important;
    padding: 0px !important;
}

.v-dropdown-content .v-select__value-container {
    position: static !important;
    position: initial !important;
    padding: 2px 5px !important;
}

.v-dropdown-width-small {
    width: 100px;
}

.v-dropdown-width-small .v-select__single-value {
    top: 64% !important;
}

.v-dropdown-width-medium {
    width: 190px;
}

.v-dropdown-width-medium .v-select__single-value {
    top: 64% !important;
}

.v-dropdown-width-large {
    width: 250px;
}

.v-dropdown-width-x-large {
    width: 400px;
}
.table-hover > tbody > tr:hover {
    background-color: #D2D2D2;
  }

.table {
  margin: 0.5vh 0vh 0.5vh 0vh;
  vertical-align: top;
  font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.table th,
.table td,
.table input {
  padding: 0.01rem;
  font-size: smaller;
}

.table tr,
.table th,
.table td{
  height: 23px;
}

/*Not to show up down icons on number input*/
/* Chrome, Safari, Edge, Opera */
.table input::-webkit-outer-spin-button,
.table input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.table input[type=number] {
  -moz-appearance: textfield;
}

.table .v-negative{
  color: red;
}

.table .v-positive{
  color: green;
}

.table .v-number{
  text-align: right;
}

.table .v-text{
  text-align: left;
}

.table input{
  max-width: 3rem;
}

.table input.v-input-4{
  max-width: 3.8rem;
}

.v-header-wrapper .v-header-content{
  padding-right: 0.2rem;
}

.table th.v-main-header{
  min-width: 9rem;
  padding-bottom: 4px;
}

.table.v-table-fit {
  display: inline-block;
  width: auto;
  table-layout: auto;
}

.table.v-table-fit thead th, table.v-table-fit tfoot th {
  width: auto !important;
}

.table.v-table-fit tbody td, table.v-table-fit tfoot td {
  width: auto !important;
}

.v-header-wrapper .v-header-content,
.table td {
  min-width: 72px;
  padding-left: 5px;
  padding-right: 5px;
}

.v-header-wrapper .v-header-content,
.table th,
.table td {
  font-size: 14px;
  white-space: nowrap;
}

.table th,
.table td {
  border: 1px solid #dee2e6 !important;
  border-collapse: collapse;
}

.table .v-main-header{
  border-top: 1px solid white !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}

.table .v-text-headers *,
.table .v-footer-row{
  font-weight: 600;
  text-align: center;
  vertical-align: top;
}

.table .v-footer-row .v-text{
  text-align: center;
}

.v-margin-table-buttons-outer{
  position: absolute;
  right: 0;
  width: 100%;
}

.v-margin-table-buttons-inner {
  right: 0;
  margin-top: 5px;
  margin-right: 25px;
  position: absolute;
  font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-margin-table-buttons-inner .v-icon-button {
  margin-left: 5px;
}

.table .v-footer-row{
  background-color: #d8d7d7 !important;
}

.v-header-wrapper,
.v-header-content{
  width: 100% !important;
  height: 100% !important;
}

/* Custom column classes */
.table .v-column-narrow-bold {
  min-width: 48px !important;
  font-weight: 600;
  text-align: center;
}

.table .v-selected-cell{
  background-color: rgba(255, 255, 4, 0.575);
}

.table .selected-row { 
  border: 2px solid #000!important;
  background-color: #f7f4e2!important;
}
.v-file-upload-icon {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.v-parameters-table {
    padding: 1.2em 0.6em 0.6em 0.8em;
}

.v-parameters-error-message{
    padding: 1.2em 0 0 1.2em;
}

.v-parameters-refresh-button{
    text-align: right;
}

.v-parameters-error-content{
    margin-top: 30px;
    margin-left: 14px;
    max-width: 328px;
    max-height: 134px;
    padding: 1.2em;
    border-radius: 4px;
    border-style: solid ;
    border-width: 1px ;
    border-color: hsl(0,0%,80%);
}

.v-parameters-refresh-button{
    text-align: right;
    margin-top: 0.5em;
}

.v-parameters-bid-parameters:not(:first-child) {
    margin-left: 30px;
}

@media screen and (max-width: 892px) {
    .v-parameters-bid-parameters:not(:first-child) {
        margin-left: 0px;
    }
}

.vtabs-container {
  font-family: Verdana, Tahoma, Geneva, sans-serif;
  font-size: 14px;
}

.vtabs-container .nav-item  {
  color: black;
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(230, 227, 227);
}

.vtabs-container .nav-item:hover  {
  background-color: rgb(245, 244, 244);
}

.vtabs-container .nav-item.disabled  {
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(245, 243, 243);
}

.vtabs-container .nav-item.active  {
  color: black;
  background-color: white;
  border-bottom: 0;
}

.vtabs-container .nav-item.active:hover  {
  background-color: white;
}

.vtabs-container .tab-pane{
  border: 1px solid #dee2e6;
  border-top-color: white;
  min-height: 67vh;  
  padding-left: 4px;
  padding-right: 4px;
}

.vtabs-container .v-infinite-width{
  overflow-x: auto;
  white-space: nowrap;
}

.vtabs-container .v-infinite-width > div {
  display: inline-block;
}

.v-tab-button {
  background-color: #0675a6;
  color: white;
  margin: 3px 3px 0 0;
}

.v-tab-button i {
  margin-right: 3px;
}

@media screen and (min-width: 768px) and (max-width: 1024px)  {
  .vtabs-container .tab-pane{
    min-height: 53vh;  
  }
}
.v-popever {
    background-color:rgb(241, 208, 99);
    border: none;
    border-radius: 4px;
}

.v-popever > * {
    color:rgb(80, 78, 78);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-popever .arrow {
    visibility: hidden;
}
.v-auction-confirmation div{
    padding: 0;
    padding-right: 15px;
}

.v-auction-warning{
    color: red;
    font-weight: 600;
}

.v-auction-bid .v-tag-input {
    min-height: 50px;
}
.v-auction-allocation-tab input{
    max-width: 100%;
}

.v-auction-allocation-export-all{
    text-align: right;
}

.v-auction-allocation-tab{
    padding-top: 10px;
}
.t-table-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-gap: 10px;
    gap: 10px;
}

.t-table-filter-container {
    display: flex;
    height: inherit;
    align-items: center;
}

.t-table-filter {
    width: 100%;
    height: 60%;
    padding: 4px;
    font-size: 14px;
}

.t-table-toolbar {
    text-align: end;
    margin: 5px;
}

.t-table-toolbar > button {
    margin-left: 5px;
}

.t-table-icon {
    color: #34495E;
}

.t-table-edit {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.t-table-delete {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.t-table-icon:hover {
    color: #C0392B;
    cursor: pointer;
}

.rdg-cell{
    contain:strict;
    contain:size layout style paint;
    position:absolute;
    height:inherit;
    padding:0px 5px 0px 0px;
    border-right:1px solid var(--border-color);
    border-bottom:1px solid var(--border-color);
    background-color:inherit;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    text-align: center;
}
.rdg-cell-frozen{
    position:-webkit-sticky;
    position:sticky;z-index:1;
    background-color:#e7e7e7
}
.rdg-cell-frozen-last{
    box-shadow:2px 0 5px -2px rgba(136,136,136,.3);
}
.rdg-cell-selected{
    box-shadow: none
}
.rdg-cell-copied{
    background-color:#ccf
}
.rdg-cell-drag-handle{
    cursor:move;
    position:absolute;
    right:0;
    bottom:0;
    width:8px;
    height:8px;
    background-color:var(--selection-color)
}
.rdg-cell-drag-handle:hover{
    width:16px;
    height:16px;
    border:2px solid var(--selection-color);
    background-color:var(--background-color)
}
.rdg-cell-dragged-over{
    background-color:#ccf
}
.rdg-cell-copied.rdg-cell-dragged-over{
    background-color:#99f
}
.rdg-cell-editing{
    padding:0
}
.rdg-checkbox-label{
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin-right:1px
}

.rdg-checkbox-label-disabled{
    cursor:default
}
.rdg-checkbox-label-disabled .rdg-checkbox{
    border-color:var(--checkbox-disabled-border-color);
    background-color:var(--checkbox-disabled-background-color)
}
.rdg-checkbox-input{
    all:unset;
    width:0;
    margin:0
}
.rdg-checkbox{
    content:'';
    width:20px;
    height:20px;
    border:2px solid var(--border-color);
    background-color:var(--background-color)
}
.rdg-checkbox-input:checked+.rdg-checkbox{
    background-color:var(--checkbox-color);
    box-shadow:inset 0 0 0 4px var(--background-color)
}
.rdg-checkbox-input:focus+.rdg-checkbox{
    border-color:var(--checkbox-focus-color)
}
.rdg {
    --color:#000;
    --border-color:#ddd;
    --summary-border-color:#aaa;
    --background-color:#fff;
    --header-background-color:#f9f9f9;
    --row-hover-background-color:#f5f5f5;
    --row-selected-background-color:#dbecfa;
    --row-selected-hover-background-color:#c9e3f8;
    --checkbox-color:#005295;
    --checkbox-focus-color:#62b8ff;
    --checkbox-disabled-border-color:#ccc;
    --checkbox-disabled-background-color:#ddd;
    --selection-color:#66afe9;
    --font-size:12px;
    contain:strict;
    contain:size layout style paint;
    content-visibility:auto;
    height: 2000px;
    border:1px solid var(--border-color);
    box-sizing:border-box;overflow-x:auto;
    overflow-y:scroll;
    -webkit-user-select:none;
    -ms-user-select:none;
        user-select:none;
    background-color:var(--background-color);
    color:var(--color);
    font-size:var(--font-size)
}
.rdg *,.rdg ::after,.rdg ::before{
    box-sizing:inherit
}
.rdg.rdg-dark{
    --color:#ddd;
    --border-color:#444;
    --summary-border-color:#555;
    --background-color:#222;
    --header-background-color:#1c1c1c;
    --row-hover-background-color:#181818;
    --row-selected-background-color:#1a73bc;
    --row-selected-hover-background-color:#1868aa;
    --checkbox-color:#95cfff;
    --checkbox-focus-color:#c8e6ff;
    --checkbox-disabled-border-color:#000;
    --checkbox-disabled-background-color:#333
}
@supports not (contain:strict){
    .rdg{
        position:relative;z-index:0
    }
}
.rdg-focus-sink{
    position:-webkit-sticky;
    position:sticky;
    top:0;
    left:0;
    height:0;
    width:0;
    outline:0
}
.rdg-viewport-dragging .rdg-row{
    cursor:move
}
.rdg-editor-container{
    display:contents
}.rdg-text-editor{
    -webkit-appearance:none;
    -moz-appearance:none;
         appearance:none;
    box-sizing:border-box;
    width:100%;
    height:100%;
    padding:0 6px;
    border:2px solid #ccc;
    vertical-align:top;
    color:var(--color);
    background-color:var(--background-color);
    font-family:inherit;font-size:var(--font-size)
}
.rdg-text-editor:focus{
    border-color:var(--selection-color);
    outline:0
}
.rdg-text-editor::-webkit-input-placeholder{
    color:#999;
    opacity:1
}
.rdg-text-editor:-ms-input-placeholder{
    color:#999;
    opacity:1
}
.rdg-text-editor::placeholder{
    color:#999;
    opacity:1
}
.rdg-filter-row,.rdg-header-row{
    contain:strict;
    contain:size layout style paint;
    display:flex;
    width:var(--row-width);
    position:-webkit-sticky;
    position:sticky;
    background-color:var(--header-background-color);
    font-weight:700;z-index:3
}
.rdg-header-row{
    height:var(--header-row-height);
    line-height:var(--header-row-height);
    top:0
}
.rdg-filter-row{
    height:var(--filter-row-height);
    line-height:var(--filter-row-height);
    top:var(--header-row-height)
}
.rdg-header-cell-resizer{
    cursor:col-resize;
    position:absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 5px;
    background-color: #edf0f1;
}
.rdg-cell .Select{
    max-height:30px;
    font-size:12px;
    font-weight:400
}
.rdg-header-sort-cell{
    cursor:pointer;
    display:flex
}
.rdg-header-sort-name{
    flex-grow:1;
    overflow:hidden;
    text-overflow:ellipsis
}
.rdg-row{
    contain:strict;
    contain:size layout style paint;
    display:flex;
    position:absolute;
    left:0;
    width:var(--row-width);
    height:var(--row-height);
    line-height:var(--row-height);
    background-color:var(--background-color)
}
.rdg-row:hover{
    background-color:var(--row-hover-background-color)
}
.rdg-row-selected{
    background-color:var(--row-selected-background-color)
}
.rdg-row-selected:hover{
    background-color:var(--row-selected-hover-background-color)
}
.rdg-summary-row{
    position:-webkit-sticky;
    position:sticky;z-index:3
}
.rdg-summary-row>.rdg-cell{
    border-top:2px solid var(--summary-border-color)
}
.rdg-group-row:not(.rdg-row-selected){
    background-color:var(--header-background-color)
}
.rdg-group-row>.rdg-cell:not(:last-child):not(.rdg-cell-frozen-last){
    border-right:none
}
.rdg-group-row-selected::after{
    content:"";
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    box-shadow:inset 0 0 0 2px var(--selection-color);
    pointer-events:none;
    z-index:2
}
.rdg-group-row-selected>.rdg-cell:first-child{
    box-shadow:inset 2px 0 0 0 var(--selection-color)
}
.rdg-group-cell-content{
    outline:0
}
.rdg-caret{
    margin-left:4px;
    stroke:currentColor;
    stroke-width:1.5px;
    fill:transparent;
    vertical-align:middle
}
.rdg-caret>path{
    transition:d .1s
}

.colSpanClassname {
    background-color: #bad3e5;
    color: black;
    text-align: center;
}

.headerClassname {
    background-color: #586772;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.end-of-week {
    background-color: #67b7ff;
}

.table-summary {
    background-color:#c1bdbd;
}


.react-datepicker__close-icon::after { 
    background-color: #99A3A4  !important;
}

.t-datepicker-content{
    margin: 12px 0px 0px 25px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.t-datepicker-label {
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-datepicker-content input {
    padding-left: 5px;
    font-size: 14px;
    height: 38px;
    width: 250px;
    border-radius: 1px ;
    border: 1px solid  hsl(0,0%,80%);
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.t-datepicker-content input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.t-datepicker-content input:hover {
    border-color: hsl(0,0%,70%);
}

.t-datetimepicker input {
    width: 192px;
}

.t-datepicker input {
    width: 150px;
}
.t-dropdown-content {
    margin: 3px 0px 0px 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.t-dropdown-label {
    padding-bottom: 5px;
    padding-right: 15px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-dropdown-content .css-1pahdxg-control {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
    border-radius: 1px !important;
}

.t-dropdown-content .css-1pahdxg-control:hover {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
    border-radius: 1px !important;
}

.t-dropdown-content .css-9gakcf-option {
    background-color: #566573 !important;
}

.t-dropdown-content .css-1n7v3ny-option {
    background-color: #E5E7E9 !important;
}

.t-dropdown-content .v-select__control {
    align-items : baseline;
    border-style: none;
}

.t-dropdown-content .css-26l3qy-menu {
    border-radius: 1px !important;
}

.t-dropdown-content .v-select__single-value, .css-yt9ioa-option, .css-9gakcf-option, .css-1n7v3ny-option, .css-107lb6w-singleValue {
    font-size: 14px !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif !important;
}

.t-dropdown-content .css-yk16xz-control {
    border-radius: 1px !important;
}

.t-dropdown-content  .v-select__single-value {
    color: #000 !important;
}

.t-dropdown-content .css-g1d714-ValueContainer {
    position: static !important;
    position: initial !important;
    padding: 0px !important;
}

.t-dropdown-content .v-select__value-container {
    position: static !important;
    position: initial !important;
    padding: 2px 5px !important;
}

.t-dropdown-width-small {
    width: 150px;
}

.t-dropdown-width-small .v-select__single-value {
    top: 64% !important;
}

.t-dropdown-width-medium {
    width: 190px;
}

.t-dropdown-width-medium .v-select__single-value {
    top: 64% !important;
}

.t-dropdown-width-large {
    width: 250px;
}

.t-dropdown-width-x-large {
    width: 400px;
}

.v-select {
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    background-color: white;
}
.v-currency-labels {
    margin: 10px 0 0 0;
    font-weight: 600;
    text-align: right;
}

.v-currency-labels > * {    
    margin: 0 5px ;
}

.v-offer-confirmation div{
    padding: 0;
    padding-right: 15px;
}

.v-flag-icon {
    width: 19px;
}
.v-matrix th,
.v-matrix td {
    min-width: 60px;
    width: 60px;
    padding: 0;
}

.v-matrix-base-color-red {
    background-color: rgb(253, 82, 82);
}

.v-matrix-base-color-blue {
    background-color: rgb(78, 110, 253);
}

.v-matrix-cell-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.v-matrix-cell-block {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.v-matrix-cell-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: right;
}

.v-matrix .v-period-column {
    text-align: center;
    font-weight: 600;
}

.v-matrix .v-header {
    text-align: right;
}

.v-matrix .v-header-negative:nth-child(2n-1) {
    font-weight: 400;
}

.v-matrix .v-header-negative:nth-child(2n) {
    font-weight: 600;
}

.v-matrix .v-header-positive:nth-child(2n-1) {
    font-weight: 600;
}

.v-matrix .v-header-positive:nth-child(2n) {
    font-weight: 400;
}

.v-matrix span {
    margin: 0 5px;
}
.v-component-spinner-container {
    width: 100%;
}

.v-component-spinner {
    text-align: center;
    width: 100%;
    margin-top: 20%;
}

.v-component-spinner-loader {
    background-color: rgba(0, 0, 0, 0.13);
}
.v-horizontal-table{
    display:inline;
    display:initial;
}

.v-horizontal-table .v-filled-header{
    background-color: rgba(231, 209, 167, 0.719);
}

.v-horizontal-table .v-offer-type-sell{
    background-color: rgba(167, 218, 231, 0.719);
}

.v-horizontal-table .v-offer-type-buy{
    background-color: rgba(233, 170, 155, 0.719);
}

.v-horizontal-table td.v-filled-header-first,
.v-horizontal-table td.v-filled-header,
.v-horizontal-table th.v-filled-header{
    border-right: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header{
    border-top: 2px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header:nth-child(2n-1){
    border-right: 1px solid black !important;
}

.v-horizontal-table th.v-sub-filled-header:nth-child(2n){
    border-left: 1px solid black !important;
}

.v-horizontal-table th.v-info-end,
.v-horizontal-table td.v-info-end {
    border-right: 2px solid black !important;
}

.v-horizontal-table td.v-info-order {
    border-right: 2px solid black !important;
    border-left: 1px solid black !important;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;
    text-align: center;
    font-weight: 600;
}

.v-horizontal-table .v-number{
    text-align: right;
    padding-left: 10px;
    padding-right: 2px;
    font-weight: normal;
    font-weight: initial;
    border: 1px solid black !important;
}

.v-horizontal-table .v-text{
    padding-right: 10px;
    padding-left: 2px;
    font-weight: normal;
    font-weight: initial;
    border: 1px solid black !important;
}

.v-horizontal-table .v-last-row{
    border-bottom: 2px solid black !important;
}
.v-control-levels {
    display: inline-block;
    white-space: nowrap;
    min-height: 690px;
    min-width: 300px;
}

.v-control-levels-bordered {
    border: 1px solid;
}

.v-control-levels > div:not(.v-control-header-wrapper):not(.v-control-header-button) {
    display: inline-block;
    padding: 0 8px;
}

.v-control-levels .v-control-header-wrapper {
    text-align: center;
    margin-top: -10px;
    margin-left: 10px;
    text-align: left;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
}

.v-control-levels .v-control-header-wrapper .v-control-header {
    background-color: white;
    font-weight: 600;
}

.v-control-levels .v-control-header-button {
    text-align: right;
    margin: 0 10px;
    padding: 0;
}
.v-vertical-tab-container .nav-pills {
  height: 100%;
  white-space: nowrap;
  font-weight: 600;
}

.v-vertical-tab-container .nav-item {
  margin-top: 1px;
  color: black;
  font-weight: 600;
  background-color: rgb(230, 227, 227);
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
}

.v-vertical-tab-container .v-vertical-tab-header {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  flex-grow: unset;
} 

.v-vertical-tab-container .v-vertical-tab-header .nav-link {
  color: #000;
}

.v-vertical-tab-container .v-disabled-vertical-tabs .nav-link  {
  font-weight: 600;
  border: 1px solid #dee2e6;
  background-color: rgb(245, 243, 243);
  pointer-events: none;
  color: #6c757d;
}

.v-vertical-tab-container .v-vertical-tab-header .nav-link.active {
  color: black;
  background-color: white;
}

.v-vertical-tab-container .tab-pane {
  padding: 10px;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.v-vertical-tab-container .row {
  margin-right: 0;
  flex-wrap: nowrap;
}

.v-vertical-tab-content {
  margin-bottom: 10px;
  width: 100%;
}

.v-vertical-tab-container,
.v-vertical-tab-content {
  width: 100%;
}

.v-infinite-width .tab-pane {
  white-space: nowrap;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

.v-infinite-width .tab-pane .v-table-list div {
  display: inline-block;
} 
.v-colored-header-v1 {
    background-color: #acd3e6 !important;
}

.v-colored-header-v2 {
    background-color :#cee7f2;
}
.v-lt-allocation-table input {
    max-width: 100%;
}

.v-lt-allocation-vertical-tabs {
    margin-left: 15px;
    vertical-align: text-top;
    margin-top: 28px;
}

.v-colored-allocation-v1 {
    background-color: #acd3e6 !important;
}

.v-colored-allocation-v2 {
    background-color :#cee7f2 !important;
}

.v-lt-allocation-not-found {
    font-weight: 600;
    margin: 5px;
    padding: 5px;
}

.v-lt-allocation-add-button {
    text-align: right;
    margin-top: 5px;
    padding: 0px;
}
/*.v-accordion, .v-accordion div {
    display: none !important;
}
*/

.v-accordion .btn-link {
    font-weight: 600;
    font-size: 14px;
    color: #232f3e;
    text-decoration: none;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-accordion .btn:focus {
    box-shadow: none;
}

.v-accordion-icon {
    font-weight: 600;
    font-size: 14px;
    color: #232f3e;
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-accordion-icon:hover {
    color: #0675a6;
} 

.v-accordion-collapse {
    padding-left: 14px;
}

.v-accordion .btn {
    padding: 0 0 5px 0;
}
.v-lt-add-modal-label {
    padding-top: 8px;
}
.v-pnl-summary-table td,
.v-pnl-summary-table th,
.v-pnl-detail-table td,
.v-pnl-detail-table th {
    padding: 0 5px !important;
    text-align: center;
    vertical-align: middle !important;
}

.v-pnl-summary-table th{
    color: black;
}

.v-pnl-detail-table th {
    color: white;
}

.v-pnl-summary-table .v-pnl-date-row,
.v-pnl-detail-table .v-pnl-date-row {
    font-weight: 600;
    color: #141414;
    background-color: #E7ECEF;
}

.v-pnl-summary-table .v-pnl-date-header ,
.v-pnl-detail-table .v-pnl-date-header {
    color:#141414;
    background-color: #E7ECEF;
}

.v-pnl-summary-table .v-row-group-1,
.v-pnl-detail-table .v-row-group-1 {
    background-color: rgba(255, 253, 253, 0.76);
}

.v-pnl-summary-table .v-row-group-2,
.v-pnl-detail-table .v-row-group-2{
    background-color: rgba(241, 241, 241, 0.76);
}

.v-pnl-summary-table .v-sum-of-borders{
    background-color: rgba(234, 241, 247, 0.76);
}

.v-fixed-button-down {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.v-fixed-button-up {
    position: fixed;
    bottom: 40px;
    right: 10px;
}

.v-fixed-button-clear {
    position: fixed;
    bottom: 70px;
    right: 10px;
}

.v-fixed-button-up button,
.v-fixed-button-down button,
.v-fixed-button-clear button {
    background-color: rgba(234, 241, 247, 0.76) !important;
    color: black !important;
    opacity: 0.5;
    width: 75px;
    text-align: left;
    padding: 0;
}

.v-pnl-summary-table .v-data-group-1,
.v-pnl-detail-table .v-data-group-1{
    background-color: #C5DDF0;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-pnl-summary-table .v-data-group-2,
.v-pnl-detail-table .v-data-group-2{
    background-color: #F8F9FA;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-pnl-selected-row {
    border: 2px solid black !important;
    background-color: rgb(247, 244, 226) !important;
}
.v-checkbox-group {
    white-space: nowrap;
    margin-top: 9px;
}

.v-checkbox-group .v-checkbox-title label {
    margin: 0;
}

.v-checkbox-group .v-checkbox-title {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-weight: 600;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    margin: 0 3px 0 0;
}

.v-checkbox-group .v-checkbox-group-inputs {
    display: flex;
    flex-wrap: wrap;
}

.v-checkbox-group .v-checkbox-group-inputs input {
    margin: 0 5px 0 0;
}

.v-checkbox-group-inputs > label {
    margin-left: 15px;
}

.v-pnl-detail-table .v-weak-column{
    opacity: 0.5;
}

.v-pnl-detail-table span.v-negative{
    color: red;
  }
  
.v-pnl-detail-table span.v-positive{
    color: green;
}

.v-pnl-detail-table .v-pnl-column{
    background-color: #FEF9E7 !important;
    color: #141414 !important;
}

.v-pnl-detail-table .pnl-unit-label {
    font-size: 10px;
    font-weight: 400;
}

.v-pnl-detail-table .pnl-darker-column {
    background-color: #eeeeee;
}

.v-pnl-detail-table .pnl-tso-label {
    font-weight: 400;
}

/* Popup container */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  /* The actual popup (appears on top) */
  .popup .popuptext {
    visibility: hidden;
    width: 160px;
    word-wrap:break-word;
    display:block;
    white-space: normal;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
  }
  
  /* Popup arrow */
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Toggle this class when clicking on the popup container (hide and show the popup) */
  .popup .show {
    visibility: visible;
  }
  
.v-paging{
    white-space: nowrap;
    margin: 5px 0;
}

.v-paging button,
.v-paging label {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.v-paging ul{
    margin: 0;
    padding-left: 15px;
}

.v-paging .page-link {
    padding: 5px !important;
}

.v-paging .v-paging-record-count  {
    display: inline-block;
    margin: 4.5px 0;
    margin-right: 5px;
}

.v-paging .v-paging-record-count select {
    margin: 0 5px;
}

.v-paging li.v-selected button {
    font-weight: 900;
}

.pnl_ftr_light_font {
    font-size: 12px;
    font-weight: 400;
}

.pnl_ftr_no_value {
    font-weight: 600;
    margin: 15px;
    padding: 10px;
}
.v-custompnl-detail {
    margin: 30px 0px 0px 0px;
}

.v-custompnl-messagebox {
    margin-top: 15px;
}

.v-custompnl-add-direction {
    text-align: right;
    margin-top: 5px;
    padding: 0px
}

.v-custompnl-no-direction {
    font-weight: 600;
    margin: 5px; 
    padding: 5px;
}
.v-messagebox {
    height: 135px;
    width: 305px;
    resize: none;
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
}

.v-messagebox:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-messagebox-limit {
    font-size: 13px;
    margin-right: 2px;
    color: #808B96;
}
.v-pnl-summary-table .v-pnl-total {
    font-weight: 600;
    color: #141414;
    background-color: #f2f5f788;
}

.v-pnl-summary-table .v-pnl-direction-col {
    font-weight: 600;
    color: #141414;
    background-color: #E7ECEF;
    min-width: 105px;
}

.v-service-cost-container > div {
    margin-top: 11px;
}
.v-flows-hour-row {
    font-weight: 600;
    color: #141414;
    background-color: #E7ECEF;
}

.v-infinite-width{
    display: flex;
    margin-right: -5px;
}

.v-row-group-1 {
    background-color: #a1b6c7;
}

.v-row-group-2{
    background-color: #F8F9FA;
}

.v-data-group-1{
    background-color: #C5DDF0;
    color: #141414;
    border-right: 3px rgb(151, 151, 151) solid !important;
    border-bottom: 3px rgb(151, 151, 151) solid !important;
    text-align: center;
}

.v-data-group-2{
    background-color: #F8F9FA;
    color: #141414;
    border-right: 3px rgb(151, 151, 151) solid !important;
    border-bottom: 3px rgb(151, 151, 151) solid !important;
    text-align: center;
}

.v-flows-date-header {
    color:#141414;
    background-color: #E7ECEF;
    border: 'solid 1px #dadada';
}



.v-infinite-width{
    margin-left: -2px;
}
.v-cb-invoice-summary-table td,
.v-cb-invoice-summary-table th {
    padding: 0 5px !important;
    text-align: center;
    vertical-align: middle !important;
}

.v-cb-invoice-summary-table th {
    color: black;
}

.v-cb-invoice-summary-table .v-cb-invoice-date-header {
    color:#141414;
    background-color: #E7ECEF;
}

.v-cb-invoice-summary-table .v-row-group-1 {
    background-color: rgba(255, 253, 253, 0.76);
}

.v-cb-invoice-summary-table .v-row-group-2 {
    background-color: rgba(241, 241, 241, 0.76);
}

.v-cb-invoice-summary-table .v-data-group-1 {
    background-color: #C5DDF0;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-cb-invoice-summary-table .v-data-group-2 {
    background-color: #F8F9FA;
    color: #141414;
    border-right: 1px rgb(151, 151, 151) solid !important;
    border-bottom: 1px rgb(151, 151, 151) solid !important;
}

.v-cb-invoice-selected-row {
    border: 2px solid black !important;
    background-color: rgb(247, 244, 226) !important;
}

.v-cb-invoice-summary-table .v-weak-column{
    opacity: 0.5;
}

.v-colored-header-v1 {
    background-color: #acd3e6 !important;
}

.v-colored-header-v2 {
    background-color :#d9d9d9;
}

.v-not-found{
    text-align: center;
}
.v-spinner-modal-dialog {
    margin-left: auto;
    margin-right: auto;
    top:20%;
    width:50px;
}

.v-spinner-modal .modal-content  {
    border: none;
}

.v-spinner-modal .v-spinner-icon {
    text-align: center;
    display: inline-block;
    width: auto;
    position: absolute;
    color: #2a7cbb;
}
.v-navbar .bg-dark {
    width: 100%;
    border-bottom: 1px solid #363636;
    font-size: 14px;
    background-color: #232f3e !important;
    z-index: 6001;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-navbar  a {
    color: #fff !important;
    text-decoration: none !important;
}

.v-navbar  a:hover,
.v-navbar  button:hover {
    color: #ff9900 !important;
    text-decoration: none !important;
}

.v-navbar .dropdown-menu {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item {
    font-size: 14px !important;
    color: #fff !important;
}

.v-navbar .dropdown-item:hover {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:focus {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:active {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:focus-within {
    background-color: #232f3e !important;
}

.v-navbar-home-link {
    padding: 0.5rem 1rem;
}

.v-navbar .bg-light {
    font-size: 15px;
}

.v-navbar .user-info-divider {
    display: none;
}

@media screen and (max-width: 992px) {
        .v-navbar-home-link {
            padding: 1rem 0px 0px;
        }

        .v-navbar .dropdown-menu {
            border: none;
        }

        .v-navbar .user-info-divider {
            display: block;
        }
        
        .v-navdropdown:not(:first-child) {
            margin-left: 10px;
        }
}
.v-user-info-content {
    color: #fff;
    display: flex;
    align-items: center;
}

.v-user-info-content span {
    margin-left: 5px;
    margin-right: 10px;
}

.v-user-log-out button {
    border-width: 0px;
    background: none;
    padding: 2px;
}

.v-user-log-out-icon {
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-user-log-out-icon:hover {
    color: #ff9900;
}

.v-user-log-out button:focus {
    border-width: 0px;
    background: none;
    outline: 0px;
}

.v-external-link {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .v-user-info-content {
        display: block;
    }

    .v-user-detail {
        color: #95a5a6;
    }
    
}
.v-base-page {
    min-height: calc(110vh - 88px);
    overflow: hidden;
    padding-top: 5px;
}

.v-base-page, .v-base-page .container  {
    max-width: calc(110vw - 175px) !important;
    margin-left: auto;
    margin-right: auto;
}

.v-content-container {
    background-color: #fff;
    border-top: 1px solid #eaeded;
    border-radius: 4px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-bottom: 16px;
}

.v-content-container .card-header {
    font-size: 1.2rem;
    line-height: 2rem;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    font-weight: 700;
    color: #16191f;
}

.v-content-container .card {
    border: none;
}

@media screen and (max-width: 768px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 25px) !important;
    }
}


@media screen and (max-width: 1024px) {
    .v-base-page, .v-base-page .container  {
        max-width: calc(100vw - 45px) !important;
    }
}
.spaces-centered-vertically {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.spaces-centered {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
}

.spaces-clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.spaces-fixedsize-layout {
	overflow: hidden;
	position: relative;
}

.spaces-fullpage-layout {
	position: fixed;
	overflow: hidden;
}

.spaces-resize-handle {
	transform: translateZ(0px);
    background-color:#d7e4ef !important;
    width: 10px !important;
    display: block;
    height: 100%;
    cursor: ew-resize;
	z-index: 50 !important;
	margin-left: 12px;
	border-radius: 3px;
}

.spaces-resize-handle:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
}

.spaces-resize-handle:after {
	margin: 450px;
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(50% - 20px);
    display: flex;
    background: #232f3e;
    border-radius: 34px;
    width: 4px;
    height: 45px;
    margin-left: 3px;
    opacity: 1;
}

.spaces-touch-handle {
	position: absolute;
	z-index: 9998;
	pointer-events: all;
	background: transparent;
}

.spaces-resize-handle.resize-left {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-left:before {
	cursor: ew-resize;
}

.spaces-touch-handle.resize-left {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-right {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-right:before {
	cursor: ew-resize;
}

.spaces-touch-handle.resize-right {
	top: 0;
	bottom: 0;
}

.spaces-resize-handle.resize-top {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-top:before {
	cursor: n-resize;
}

.spaces-touch-handle.resize-top {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-bottom {
	left: 0;
	right: 0;
}

.spaces-resize-handle.resize-bottom:before {
	cursor: s-resize;
}

.spaces-touch-handle.resize-bottom {
	left: 0;
	right: 0;
}

.spaces-space {
	overflow: hidden;
	touch-action: none;
	box-sizing: border-box;
}

.spaces-resizing .spaces-space {
	transition: none !important;
}

.spaces-space .spaces-space-inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
}

.spaces-space.scrollable .spaces-space-inner,
.spaces-space.scrollable-a {
	overflow: auto;
	touch-action: auto;
}
.t-left-side-container .MuiAccordionDetails-root {
    padding: 12px 12px 0 3px;
}

.t-left-side-container-content .rdg {
    height: 587px !important;
}

.t-left-side-container-content .MuiPaper-elevation1 {
    box-shadow: none;
}
.t-right-side-container {
    padding-left: 8px;
 }
.t-modal {
    top:7%;
}

.t-modal-30w{
    width:450px;
    max-width: none !important;
}

.t-modal-50w{
    width:500px;
    max-width: none !important;
}

.t-modal-60w{
    width:600px;
    max-width: none !important;
}

.t-modal-90w {
    width:900px;
    max-width: none !important;
}

.t-modal-body .container > div {
    margin-top: 2%;
}

.modal-content {
    border-radius: 0;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.modal-header {
    background-color: #fafafa;
}

.modal-title {
    font-size: 18px !important;
}

.t-modal-error-icon-col{
    width: auto;
}

.t-modal-error-icon{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    text-align: center;
}
.MuiTabs-root {
    display: flex !important;
    overflow: hidden !important;
    min-height: 30px !important;
}

.MuiTab-root {
    padding: 6px 12px !important;
    overflow: hidden !important;
    position: relative !important;
    font-size: 0.875rem !important;
    max-width: 264px !important;
    min-width: 72px !important;
    box-sizing: border-box !important;
    min-height: 30px !important;
    text-align: center !important;
    flex-shrink: 0 !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    white-space: normal !important;
    letter-spacing: 0.02857em !important;
    text-transform: none !important;
}
.t-messagebox {
    height: 135px;
    width: 305px;
    resize: none;
    border-radius: 1px;
    border: 1px solid hsl(0,0%,80%);
}

.t-messagebox:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.t-messagebox-limit {
    font-size: 13px;
    margin-right: 2px;
    color: #808B96;
}
.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  display: inline-block;
  font-size: 10px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 5px;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-items {
    margin: 6px;
    display: 'flex';
    justify-content: 'center';
    flex-wrap: 'wrap';
    flex-direction: 'column';
    list-style: 'none';
    margin: 5;
    font-size: 10px;
    padding: 4px;
}

.radio-item {
    display: contents !important;
    font-size: 10px !important;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.MuiTypography-body1 {
    font-size: 10px !important;
}


.icon {
    border-radius: '50%';
    width: 16px;
    height: 16px;
    box-shadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
    background-color: '#f5f8fa';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))';
}

.icon .Mui-focusVisible {
    outline: '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
}

.icon .input:hover {
    background-color: '#ebf1f5';
}

.icon .input:disabled {
    box-shadow: 'none';
        background: 'rgba(206,217,224,.5)';
}
.t-file-upload-icon {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.t-icon-button{
    cursor: pointer;
}

.t-disabled-link-button, .t-link-button{
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

span.t-disabled-link-button, .btn.t-disabled-link-button {
    opacity: 0.65;
    cursor: default !important;
    pointer-events: none;
}

.t-upload-file-label {
    padding-left: 5px;
    cursor: pointer;
    font-weight: 600;
}

.t-upload-file-label:hover {
    text-decoration: underline;
}
.add-hour-div {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.add-button {
    margin: 15px !important;
    width: 50px;
    height: 22px;
    text-decoration: none !important;
}

.add-hour-input {
    width: 100px;
    height: 22px;
    margin: 7px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
}

.error {
    margin: 10px;
    font-size: 90%;
    color: tomato;
  }

  .day-hour-font {
    font-style: "italic" !important;
    font-size: "12px" !important;
  }
.t-price-curve-label-container {
    text-align: center;
}

.t-price-curve-labels {
    vertical-align: sub;
    font-weight: 600;
}
.t-cost-detail-label-container {
    text-align: center;
}

.t-cost-detail-labels {
    vertical-align: sub;
    font-weight: 600;
}
input[type=file]::file-selector-button {
    border: 2px solid #b7e2e9;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #b7e2e9;
    transition: 1s;
    cursor: pointer;
  }
  
  input[type=file]::file-selector-button:hover {
    border: 2px solid #b7e2e9;
    background-color: #b7e2e9;
    border-color: #b7e2e9;
  }
.t-buy-button {
    width: 120px;
	background: linear-gradient(221deg, transparent 25%, #97c713 30%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
}

.unselect-buy {
    background: linear-gradient(221deg, transparent 26%, #555 84%) ;
}

.t-sell-button {
    width: 120px;
    background: linear-gradient(45deg, transparent 25%, #e0142e 30%);
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #fff;
    border-radius: 1px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    margin-left: -50px;
    border-radius: 5px;
}

.unselect-sell {
    background: linear-gradient(45deg, transparent 25%, #555 70%);
}

.t-position-div {
    margin: 12px 0px 0px 25px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.t-position-label {
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-table-buy {
    align-items: center;
    background-color: #97c713;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    font-size: 0.6875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.5px;
    min-width: 20px;
    padding: 4px 8px;
    text-transform: uppercase;
}

.t-table-sell {
    align-items: center;
    background-color: #e0142e;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    font-size: 0.6875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.5px;
    min-width: 20px;
    padding: 4px 8px;
    text-transform: uppercase;
}


.cost-input {
    width: 70%;
    height: 30px;
    padding-left: 2px;
}

.cost-save-button {
    padding: 2px;
    height: 30px;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    background-color: #C75313;
    color: #fff;
    border-radius: 1px;
    border: limegreen;
    font-weight: 600;
    font-size: 14px;
}

.notification-cell {
  display:inline-block;
}
  
.add-margin {
  margin-top:20px;
}
  
.notification {
  text-align:left;
  padding: 7px 0;
  border-radius:4px;
  top: 0px;
  position:relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
  margin: 3px;
}

.notification-default {
  background-color:#fff;
}

.notification-book {
  background-color: #f5f5ea;
}

.notification-contract {
  background-color: #daf3ec;
}

.notification-market {
  background-color: #EAF7F5;
}

.notification-company {
  background-color: #E4ECFB;
}

.notification-title {
  display: flex;
  justify-content: space-between;
}

.notification-date {
  color: #bfb8b8;
  margin-bottom: 8px;
}
  
.notification:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius:4px;
  border-bottom-left-radius: 4px;
}
  
.notification-icon{
  position:absolute;
  top:50%;
  left:22px;
  transform:translateY(-50%);
  width:40px;
  height:40px;
  padding: 7px;
  border-radius:50%;
  display:flex;
  color: #fafafa;
  background-color: #bdbdbd;
  overflow: hidden;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 50%;
  justify-content: center;
}
  
.notification-type {
  color: #3e3e3e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  text-transform: capitalize;
}
  
.notification-message {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: #878787;
  font-style: italic;
}
  
.notification-content{
  padding-left:70px;
  padding-right:60px;
}
  
.notification-green:before{
  background-color:#2BDE3F;
}
  
  
.notification-blue:before{
  background-color:#1D72F3;
}
  
.notification-yellow:before{
  background-color:#FFC007;
}

.notification-red:before{
  background-color:#f10909;
}
.position-counter-party {
    background-color: #edda97 !important;
}

.position-market {
    background-color:  #cae193 !important;
}
.table-summary {
    background-color: #c1bdbd;
    text-align: right;
    font-size: 14px;
}

.summary {
    background-color: #c1bdbd;
    font-size: 14px;
}

.overall-table-summary{
    background-color: #a09d9d;
    text-align: right;
    font-size: 14px;
}

.overall-summary{
    background-color: #a09d9d;
    font-size: 14px;
}

.bold-summary{
    font-weight: bold;
}

.bold-summary{
    font-weight: bold;
    font-size: 14px;
}
.bold-text{
    font-weight: bold;
    font-size: 14px;
}

.bold-subtotal-text{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px
}

.bold-total-text{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
}

.bold-payment-text{
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.bold-subtotal-negative-summary{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px;
    color: red;
}

.bold-subtotal-positive-summary{
    background-color: #eee9e9;
    font-weight: bold;
    font-size: 14px;
    color: black;
}

.payment{
    background-color: #f5f5f5;
    font-style: italic;
    font-size: 15px;
    color: rgb(78, 77, 77);
}

.payment-text{
    background-color: #f5f5f5;
    font-size: 13px;
    color: black;
}

.negative-summary{
    font-style: italic;
    font-size: 15px;
    color: #ff0000;
}

.positive-summary{
    font-style: italic;
    font-size: 15px;
    color: black;
}

.bold-negative-summary{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
    color: red;
}

.bold-positive-summary{
    background-color: #c1bdbd;
    font-weight: bold;
    font-size: 14px;
    color: black;
}
.sidebar {
    max-width: 240px;
    height: 100vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sidebar-item-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .sidebar-item-clicked {
    background-color: #f3e4d1;
  }
  
  .sidebar-item-icon {
    margin-right: 6px;
  }
  
  .sidebar-item-text {
    width: 100%;
  }
  
  .sidebar-item-expand-arrow {
    font-size: 1.2rem !important;
  }
  
  .sidebar-item-expand-arrow-expanded {
    color: #09bb12;
    font-weight: bold;
  }
.t-spinner-modal-dialog {
    margin-left: auto;
    margin-right: auto;
    top:20%;
    width:50px;
}

.t-spinner-modal .modal-content  {
    border: none;
}

.t-spinner-modal .t-spinner-icon {
    text-align: center;
    display: inline-block;
    width: auto;
    position: absolute;
    color: #2a7cbb;
}
.MuiBadge-anchorOriginTopRightRectangle {
    top: 3px !important;
    right: -10px !important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.MuiBadge-colorError {
    color: #fff;
    background-color: #c14940 !important;
}

.t-user-info-content {
    color: #454D4E;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.t-user-info-content span {
    margin-left: 5px;
    margin-right: 10px;
}

.t-user-log-out button {
    border-width: 0px;
    background: none;
    padding: 2px;
}

.t-user-log-out-icon {
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.t-user-log-out-icon:hover {
    color: #ff9900;
}

.t-user-log-out button:focus {
    border-width: 0px;
    background: none;
    outline: 0px;
}

.t-external-link {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .t-user-info-content {
        display: block;
    }

    .t-user-detail {
        color: #95a5a6;
    }
    
}


.v-footer-text {
    font-size: 11px;
    color: #16191f;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}
.v-alert-list { 
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
} 

.v-alert-message {
    margin-right: 15px;
}

.v-alert-list .v-alert-container{ 
    padding-top: 60px;
}

/* 
Css is handled with javascript(jss) in the component but manual css classes are also defined to be used if needed:
v-alert-list
v-alert-transition-container
v-alert-container
v-alert-box
v-alert-message-container
v-alert-message-headline
v-alert-message-body
v-alert-message
v-alert-close-button
*/
