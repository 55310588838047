.MuiTabs-root {
    display: flex !important;
    overflow: hidden !important;
    min-height: 30px !important;
}

.MuiTab-root {
    padding: 6px 12px !important;
    overflow: hidden !important;
    position: relative !important;
    font-size: 0.875rem !important;
    max-width: 264px !important;
    min-width: 72px !important;
    box-sizing: border-box !important;
    min-height: 30px !important;
    text-align: center !important;
    flex-shrink: 0 !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    white-space: normal !important;
    letter-spacing: 0.02857em !important;
    text-transform: none !important;
}