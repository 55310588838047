.v-user-info-content {
    color: #fff;
    display: flex;
    align-items: center;
}

.v-user-info-content span {
    margin-left: 5px;
    margin-right: 10px;
}

.v-user-log-out button {
    border-width: 0px;
    background: none;
    padding: 2px;
}

.v-user-log-out-icon {
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-user-log-out-icon:hover {
    color: #ff9900;
}

.v-user-log-out button:focus {
    border-width: 0px;
    background: none;
    outline: 0px;
}

.v-external-link {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .v-user-info-content {
        display: block;
    }

    .v-user-detail {
        color: #95a5a6;
    }
    
}