.v-currency-labels {
    margin: 10px 0 0 0;
    font-weight: 600;
    text-align: right;
}

.v-currency-labels > * {    
    margin: 0 5px ;
}

.v-offer-confirmation div{
    padding: 0;
    padding-right: 15px;
}

.v-flag-icon {
    width: 19px;
}