html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    background: linear-gradient(to right,#eee,#eaeded) !important;
}

.v-font {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-font-size {
    font-size: 14px;
}

.v-gray {
    color: #454D4E;
}

.v-red {
    color: #d93025;
}

.v-green {
    color: green;
}

.v-light-blue {
    color: #546375;
}

.v-white {
    color: #fff;
}

.v-label {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-weight: 600;
    font-size: 14px;
}

.v-button {
    background-color: #0675a6;
}

.v-cancel-button {
    background-color: #8f9396;
}

.v-button,
.v-cancel-button {
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: white;
    font-size: 14px;
}

.v-button:hover {
    color: white;
    background-color: #2a7cbb;
}

.v-cancel-button:hover {
    color: white;
    background-color: #a1a5a8;
}

.v-button:focus,
.v-cancel-button:focus {
    box-shadow: none;
}

.v-disabled-link-button,
.v-link-button{
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

.v-link-button{
    cursor: pointer;
}

.v-link-button:hover {
    color: #0675a6;
}

.btn.v-link-button:hover {
    color: white;
}

span.v-disabled-link-button,
.btn.v-disabled-link-button {
    opacity: 0.65;
    cursor: default !important;
    pointer-events: none;
}

.v-icon-button{
    cursor: pointer;
}

.v-bullet-list{
    list-style-type:disc
}

.v-bullet-list li{
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    color: #232f3e;
    font-size: 14px;
}

.v-table-list > div {
    flex: 1 1 auto;
    margin: 8px;
}

.v-checkBox {
    width: auto;
    display: block !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.v-checkBox input {
    margin-right: 3px;
}

.v-input {
    height: 38px;
    width: 250px;
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
}

.v-input:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

/* Remove Arrows/Spinners from Input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:hover {
    border-color: hsl(0,0%,70%);
}

.v-negative{
    color: red !important;
}
  
.v-positive{
    color: green !important;
}