.v-tag-input {
    border: 1px solid hsl(0,0%,80%);
    border-radius: 4px;
    position:relative;
    min-height: 80px;
    padding: 1%;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-tag-input:hover {
    border: 1px solid hsl(0,0%,70%);
}

.v-tag-input div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
}

.v-tag-input input.ReactTags__tagInputField {
    border:0;
    box-shadow: none;
    -webkit-appearance: none;
    width: 100%;

}

.v-tag-input input.ReactTags__tagInputField,
.v-tag-input .ReactTags__suggestions{
    font-size: 14px;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-tag-input .ReactTags__suggestions {
    max-height: 160px; 
    overflow: auto;
    border: 1px solid hsl(0,0%,80%);
}

.v-tag-input .ReactTags__suggestions ul{
    margin: 0;
    padding: 0;
}

.v-tag-input input.ReactTags__tagInputField:focus {
    outline: none;
}

/* Styles for selected tags */
.v-tag-input div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    display: inline-block;
    padding: 5px;
    margin: 2px 2px;
    border-radius: 2px;
    font-size: 14px;
}

.v-tag-input div.ReactTags__selected a.ReactTags__remove,
.v-tag-input div.ReactTags__selected i.ReactTags__remove  {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

.v-tag-input div.ReactTags__suggestions {
    position: absolute;
}
.v-tag-input div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
.v-tag-input div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.v-tag-input div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}