.t-modal {
    top:7%;
}

.t-modal-30w{
    width:450px;
    max-width: none !important;
}

.t-modal-50w{
    width:500px;
    max-width: none !important;
}

.t-modal-60w{
    width:600px;
    max-width: none !important;
}

.t-modal-90w {
    width:900px;
    max-width: none !important;
}

.t-modal-body .container > div {
    margin-top: 2%;
}

.modal-content {
    border-radius: 0;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.modal-header {
    background-color: #fafafa;
}

.modal-title {
    font-size: 18px !important;
}

.t-modal-error-icon-col{
    width: auto;
}

.t-modal-error-icon{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: red;
    text-align: center;
}