input[type=file]::file-selector-button {
    border: 2px solid #b7e2e9;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #b7e2e9;
    transition: 1s;
    cursor: pointer;
  }
  
  input[type=file]::file-selector-button:hover {
    border: 2px solid #b7e2e9;
    background-color: #b7e2e9;
    border-color: #b7e2e9;
  }