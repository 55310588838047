.v-navbar .bg-dark {
    width: 100%;
    border-bottom: 1px solid #363636;
    font-size: 14px;
    background-color: #232f3e !important;
    z-index: 6001;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-navbar  a {
    color: #fff !important;
    text-decoration: none !important;
}

.v-navbar  a:hover,
.v-navbar  button:hover {
    color: #ff9900 !important;
    text-decoration: none !important;
}

.v-navbar .dropdown-menu {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item {
    font-size: 14px !important;
    color: #fff !important;
}

.v-navbar .dropdown-item:hover {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:focus {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:active {
    background-color: #232f3e !important;
}

.v-navbar .dropdown-item:focus-within {
    background-color: #232f3e !important;
}

.v-navbar-home-link {
    padding: 0.5rem 1rem;
}

.v-navbar .bg-light {
    font-size: 15px;
}

.v-navbar .user-info-divider {
    display: none;
}

@media screen and (max-width: 992px) {
        .v-navbar-home-link {
            padding: 1rem 0px 0px;
        }

        .v-navbar .dropdown-menu {
            border: none;
        }

        .v-navbar .user-info-divider {
            display: block;
        }
        
        .v-navdropdown:not(:first-child) {
            margin-left: 10px;
        }
}