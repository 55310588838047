.v-filter-container > div,
.v-filter-container > .container .row {
    margin-top: 2px;
    margin-bottom: 10px;
}

.v-filter-group {
    display: flex;
}

.v-filter-label {
    min-width: 128px;
    padding-top: 10px;
}

.v-filter-buttons {
    text-align: right;
}

.v-filter-button {
    margin-left: 8px;
}

.v-filter-button i {
    margin-right: 3px;
}

.v-filter-taginput {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .v-filter-group {
        display: block;
    }

    .v-filter-container > div,
    .v-filter-container > .container .row  {
        margin-top: 0px;
        margin-bottom: 2%;
    }
}