.v-lt-allocation-table input {
    max-width: 100%;
}

.v-lt-allocation-vertical-tabs {
    margin-left: 15px;
    vertical-align: text-top;
    margin-top: 28px;
}

.v-colored-allocation-v1 {
    background-color: #acd3e6 !important;
}

.v-colored-allocation-v2 {
    background-color :#cee7f2 !important;
}

.v-lt-allocation-not-found {
    font-weight: 600;
    margin: 5px;
    padding: 5px;
}

.v-lt-allocation-add-button {
    text-align: right;
    margin-top: 5px;
    padding: 0px;
}