.v-alert-list { 
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    font-size: 14px;
} 

.v-alert-message {
    margin-right: 15px;
}

.v-alert-list .v-alert-container{ 
    padding-top: 60px;
}

/* 
Css is handled with javascript(jss) in the component but manual css classes are also defined to be used if needed:
v-alert-list
v-alert-transition-container
v-alert-container
v-alert-box
v-alert-message-container
v-alert-message-headline
v-alert-message-body
v-alert-message
v-alert-close-button
*/