.v-messagebox {
    height: 135px;
    width: 305px;
    resize: none;
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
}

.v-messagebox:focus {
    outline: none !important;
    border-width: 4px;
    border:1px solid black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-messagebox-limit {
    font-size: 13px;
    margin-right: 2px;
    color: #808B96;
}