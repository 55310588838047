.t-dropdown-content {
    margin: 3px 0px 0px 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.t-dropdown-label {
    padding-bottom: 5px;
    padding-right: 15px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.t-dropdown-content .css-1pahdxg-control {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
    border-radius: 1px !important;
}

.t-dropdown-content .css-1pahdxg-control:hover {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
    border-radius: 1px !important;
}

.t-dropdown-content .css-9gakcf-option {
    background-color: #566573 !important;
}

.t-dropdown-content .css-1n7v3ny-option {
    background-color: #E5E7E9 !important;
}

.t-dropdown-content .v-select__control {
    align-items : baseline;
    border-style: none;
}

.t-dropdown-content .css-26l3qy-menu {
    border-radius: 1px !important;
}

.t-dropdown-content .v-select__single-value, .css-yt9ioa-option, .css-9gakcf-option, .css-1n7v3ny-option, .css-107lb6w-singleValue {
    font-size: 14px !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif !important;
}

.t-dropdown-content .css-yk16xz-control {
    border-radius: 1px !important;
}

.t-dropdown-content  .v-select__single-value {
    color: #000 !important;
}

.t-dropdown-content .css-g1d714-ValueContainer {
    position: initial !important;
    padding: 0px !important;
}

.t-dropdown-content .v-select__value-container {
    position: initial !important;
    padding: 2px 5px !important;
}

.t-dropdown-width-small {
    width: 150px;
}

.t-dropdown-width-small .v-select__single-value {
    top: 64% !important;
}

.t-dropdown-width-medium {
    width: 190px;
}

.t-dropdown-width-medium .v-select__single-value {
    top: 64% !important;
}

.t-dropdown-width-large {
    width: 250px;
}

.t-dropdown-width-x-large {
    width: 400px;
}

.v-select {
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    background-color: white;
}