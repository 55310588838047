/*.v-accordion, .v-accordion div {
    display: none !important;
}
*/

.v-accordion .btn-link {
    font-weight: 600;
    font-size: 14px;
    color: #232f3e;
    text-decoration: none;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
}

.v-accordion .btn:focus {
    box-shadow: none;
}

.v-accordion-icon {
    font-weight: 600;
    font-size: 14px;
    color: #232f3e;
    font-family: FontAwesome, Verdana, Tahoma, Geneva, sans-serif;
}

.v-accordion-icon:hover {
    color: #0675a6;
} 

.v-accordion-collapse {
    padding-left: 14px;
}

.v-accordion .btn {
    padding: 0 0 5px 0;
}