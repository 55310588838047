.v-control-levels {
    display: inline-block;
    white-space: nowrap;
    min-height: 690px;
    min-width: 300px;
}

.v-control-levels-bordered {
    border: 1px solid;
}

.v-control-levels > div:not(.v-control-header-wrapper):not(.v-control-header-button) {
    display: inline-block;
    padding: 0 8px;
}

.v-control-levels .v-control-header-wrapper {
    text-align: center;
    margin-top: -10px;
    margin-left: 10px;
    text-align: left;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
}

.v-control-levels .v-control-header-wrapper .v-control-header {
    background-color: white;
    font-weight: 600;
}

.v-control-levels .v-control-header-button {
    text-align: right;
    margin: 0 10px;
    padding: 0;
}