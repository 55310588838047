.v-dropdown-content {
    display: inline-block;
}

.v-dropdown-label {
    padding-bottom: 5px;
}

.v-dropdown-content .css-1pahdxg-control {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-dropdown-content .css-1pahdxg-control:hover {
    border-color: black !important;
    box-shadow: 0 0 0 0 black !important;
}

.v-dropdown-content .css-9gakcf-option {
    background-color: #566573 !important;
}

.v-dropdown-content .css-1n7v3ny-option {
    background-color: #E5E7E9 !important;
}

.v-dropdown-content .v-select__control {
    align-items : baseline;
}

.v-dropdown-content .v-select__single-value, .css-yt9ioa-option, .css-9gakcf-option, .css-1n7v3ny-option, .css-107lb6w-singleValue {
    font-size: 14px !important;
    font-family: Verdana, Tahoma, Geneva, sans-serif !important;
}

.v-dropdown-content  .v-select__single-value {
    color: #000 !important;
}

.v-dropdown-content .css-g1d714-ValueContainer {
    position: initial !important;
    padding: 0px !important;
}

.v-dropdown-content .v-select__value-container {
    position: initial !important;
    padding: 2px 5px !important;
}

.v-dropdown-width-small {
    width: 100px;
}

.v-dropdown-width-small .v-select__single-value {
    top: 64% !important;
}

.v-dropdown-width-medium {
    width: 190px;
}

.v-dropdown-width-medium .v-select__single-value {
    top: 64% !important;
}

.v-dropdown-width-large {
    width: 250px;
}

.v-dropdown-width-x-large {
    width: 400px;
}