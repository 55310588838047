.v-component-spinner-container {
    width: 100%;
}

.v-component-spinner {
    text-align: center;
    width: 100%;
    margin-top: 20%;
}

.v-component-spinner-loader {
    background-color: rgba(0, 0, 0, 0.13);
}