.v-matrix th,
.v-matrix td {
    min-width: 60px;
    width: 60px;
    padding: 0;
}

.v-matrix-base-color-red {
    background-color: rgb(253, 82, 82);
}

.v-matrix-base-color-blue {
    background-color: rgb(78, 110, 253);
}

.v-matrix-cell-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.v-matrix-cell-block {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.v-matrix-cell-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: right;
}

.v-matrix .v-period-column {
    text-align: center;
    font-weight: 600;
}

.v-matrix .v-header {
    text-align: right;
}

.v-matrix .v-header-negative:nth-child(2n-1) {
    font-weight: 400;
}

.v-matrix .v-header-negative:nth-child(2n) {
    font-weight: 600;
}

.v-matrix .v-header-positive:nth-child(2n-1) {
    font-weight: 600;
}

.v-matrix .v-header-positive:nth-child(2n) {
    font-weight: 400;
}

.v-matrix span {
    margin: 0 5px;
}