.v-parameters-table {
    padding: 1.2em 0.6em 0.6em 0.8em;
}

.v-parameters-error-message{
    padding: 1.2em 0 0 1.2em;
}

.v-parameters-refresh-button{
    text-align: right;
}

.v-parameters-error-content{
    margin-top: 30px;
    margin-left: 14px;
    max-width: 328px;
    max-height: 134px;
    padding: 1.2em;
    border-radius: 4px;
    border-style: solid ;
    border-width: 1px ;
    border-color: hsl(0,0%,80%);
}

.v-parameters-refresh-button{
    text-align: right;
    margin-top: 0.5em;
}

.v-parameters-bid-parameters:not(:first-child) {
    margin-left: 30px;
}

@media screen and (max-width: 892px) {
    .v-parameters-bid-parameters:not(:first-child) {
        margin-left: 0px;
    }
}
