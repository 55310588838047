.v-category-item {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    border-style: solid ;
    border-width: 1px ;
    border-color: hsl(0,0%,80%);
    padding: 3% 1% 1% 1%;
    min-width: 164px;
    min-height:  158px;
    cursor: pointer;
    font-family: Verdana, Tahoma, Geneva, sans-serif;
    margin: 10px;
    vertical-align: text-top;
}

.v-category-item:hover {
    background-color: #546375;
    border-color: #657282;
}

.v-category-item-title {
    padding-top: 10px;
    max-width: 145px;
    font-weight: 700;
}

.v-category-item:hover .v-category-item-title p {
    color: #fff;
}

.v-category-item:hover .v-category-item-icon i {
    color: #fff;
}

.v-category-item a {
    text-decoration: none;
}