
.notification-cell {
  display:inline-block;
}
  
.add-margin {
  margin-top:20px;
}
  
.notification {
  text-align:left;
  padding: 7px 0;
  border-radius:4px;
  top: 0px;
  position:relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
  margin: 3px;
}

.notification-default {
  background-color:#fff;
}

.notification-book {
  background-color: #f5f5ea;
}

.notification-contract {
  background-color: #daf3ec;
}

.notification-market {
  background-color: #EAF7F5;
}

.notification-company {
  background-color: #E4ECFB;
}

.notification-title {
  display: flex;
  justify-content: space-between;
}

.notification-date {
  color: #bfb8b8;
  margin-bottom: 8px;
}
  
.notification:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius:4px;
  border-bottom-left-radius: 4px;
}
  
.notification-icon{
  position:absolute;
  top:50%;
  left:22px;
  transform:translateY(-50%);
  width:40px;
  height:40px;
  padding: 7px;
  border-radius:50%;
  display:flex;
  color: #fafafa;
  background-color: #bdbdbd;
  overflow: hidden;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}
  
.notification-type {
  color: #3e3e3e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  text-transform: capitalize;
}
  
.notification-message {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: #878787;
  font-style: italic;
}
  
.notification-content{
  padding-left:70px;
  padding-right:60px;
}
  
.notification-green:before{
  background-color:#2BDE3F;
}
  
  
.notification-blue:before{
  background-color:#1D72F3;
}
  
.notification-yellow:before{
  background-color:#FFC007;
}

.notification-red:before{
  background-color:#f10909;
}