.radio-items {
    margin: 6px;
    display: 'flex';
    justify-content: 'center';
    flex-wrap: 'wrap';
    flex-direction: 'column';
    list-style: 'none';
    margin: 5;
    font-size: 10px;
    padding: 4px;
}

.radio-item {
    display: contents !important;
    font-size: 10px !important;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.MuiTypography-body1 {
    font-size: 10px !important;
}


.icon {
    border-radius: '50%';
    width: 16px;
    height: 16px;
    box-shadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
    background-color: '#f5f8fa';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))';
}

.icon .Mui-focusVisible {
    outline: '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
}

.icon .input:hover {
    background-color: '#ebf1f5';
}

.icon .input:disabled {
    box-shadow: 'none';
        background: 'rgba(206,217,224,.5)';
}