.add-hour-div {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.add-button {
    margin: 15px !important;
    width: 50px;
    height: 22px;
    text-decoration: none !important;
}

.add-hour-input {
    width: 100px;
    height: 22px;
    margin: 7px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
}

.error {
    margin: 10px;
    font-size: 90%;
    color: tomato;
  }

  .day-hour-font {
    font-style: "italic" !important;
    font-size: "12px" !important;
  }